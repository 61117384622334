import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button} from 'react-bootstrap';
//import Button from '@mui/material/Button';
import { isObject } from 'lodash';
import React from 'react';
import { Accordion, Alert, Card, Col, Collapse, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { PART_PHOTO } from './constants/constants';
import {PART_FE} from './constants/constants';
import DescProductList from './DescProductList';
import DescProductListPrint from './DescProductListPrint';
import "./design.css";
import { ViewHOC } from './ViewHOC';
import { Link } from "react-router-dom";
import { SEARCH_MODES, SEARCH_TEXT } from "./constants/constants";
import { getQueryParam } from "./Util";
import secureLocalStorage from 'react-secure-storage';

const initialFilters = () => ({
    category: [],
    keyword: [],
    techCourier: [],
    rohs: [],
    CSR: [],
    photoFlag:'',
    FE:''
})

class ProductResults extends React.Component {
    constructor() {
        super()
        this.state = {
            isPrint: false,
            isSbom:false,
            SbomText:null,
            products: null,
            filters: initialFilters(),
            metadata: {
                category: [],
                keyword: [],
                techCourier: [],
                rohs: [],
                CSR: [],
                photoFlag: [],
                FE:[]
            },
            Ishide: false,
        }
    }

    componentDidMount() {
       //  this.props.data[0].ProductNumber  
       console.log("pipebom", this.props.data[0].PipeBomFlag);
       if(this.props.data[0].PipeBomFlag==1)
       {
        this.setState({isSbom:true}); 
        this.setState({SbomText:"Updated SBOM available"});
       }
       else if(this.props.data[0].PipeBomFlag==0)
       {
        this.setState({isSbom:true}); 
        this.setState({SbomText:"SBOM"});
       }
        if (this.props.data && !this.state.products) {
            this.getCategoryProducts(() => {
                this.triggerAll(true)
                this.generateMetdata()
            });
        }
          
    }
    
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }
    PrintFriendlyView = ()=>{
        this.setState({isPrint:true, mobileView: false});
      //  console.log("inside printfriendly")
    }


    generateMetdata = () => {
        const { filters, products } = this.state
        const { category, keyword, techCourier, rohs: rohsFilter, CSR, photoFlag:photoFilters,FE:feFilters } = filters;
        // console.log('check', category, metadata,filters, products)

        let temp = {}
        let count = 0

        let categoryFilters = category || [];
        Object.entries(products || {}).filter(([key, value]) => categoryFilters.length === 0 || (categoryFilters.indexOf(key) !== -1)).forEach(([key, value], index) => {
            let categories = temp.category || []
            let keywordFilters = keyword || [];

            Object.entries(value).filter(([catKey, catValue]) => keywordFilters.length === 0 || (keywordFilters.indexOf(catKey) !== -1)).forEach(([catKey, catValue], index) => {
                let keyWords = temp.keyword || [];
            //    console.log('aa')
                let rohs = temp.rohs || [];
                let techCouriers = temp.techCourier || [];
                let CSRFlag = temp.CSR || [];
                let photoFlag = temp.photoFlag || [];
                let FE=temp.FE || [];
                catValue.filter(product => {
              //    console.log('check check',product.PartPhotoFlag, product.PartPhotoFlag ? 'ON' : 'OFF', photoFilters,photoFilters === '-1' || photoFilters === (product.PartPhotoFlag ? 'ON' : 'OFF'))
                    return (techCourier.length === 0 || (techCourier.indexOf(product.TechCourier) !== -1))
                        && (rohsFilter.length === 0 || (rohsFilter.indexOf(product.RohsCompliant) !== -1))
                        && (CSR.length === 0 || (CSR.indexOf(product.CsrFlag) !== -1))
                        //&& (photoFlag === undefined || (product.PartPhotoFlag ? true : false) === photoFlag)
 && (!feFilters || feFilters === PART_FE.NOT_APPLICABLE 
                            || feFilters === (product.FE_MATERIAL ? PART_FE.PRESENT : PART_FE.NOT_PRESENT))                        && (!photoFilters || photoFilters === PART_PHOTO.NOT_APPLICABLE 
                            || photoFilters === (product.PartPhotoFlag ? PART_PHOTO.PRESENT : PART_PHOTO.NOT_PRESENT))
                }).forEach(product => {
                    count++;
                    categories = categories.indexOf(key) !== -1 ? categories : [...categories, key]
                    keyWords = keyWords.indexOf(catKey) !== -1 ? keyWords : [...keyWords, catKey]
                    if (product.RohsCompliant) {
                        rohs = rohs.indexOf(product.RohsCompliant) !== -1 ? rohs : [...rohs, product.RohsCompliant]
                    }
                    if (product.TechCourier) {
                        techCouriers = techCouriers.indexOf(product.TechCourier) !== -1 ? techCouriers : [...techCouriers, product.TechCourier]
                    }
                    if (product.CsrFlag) {
                        CSRFlag = CSRFlag.indexOf(product.CsrFlag) !== -1 ? CSRFlag : [...CSRFlag, product.CsrFlag]
                    }
if(product.FE_MATERIAL || product.FE_MATERIAL === false){
                        const fePresent = product.FE_MATERIAL ? PART_FE.PRESENT : PART_FE.NOT_PRESENT;
                        FE = FE.indexOf(fePresent) !== -1 ? FE : [...FE,fePresent]
                    }                    if(product.PartPhotoFlag || product.PartPhotoFlag === 0){
                        const photoPresent = product.PartPhotoFlag ? PART_PHOTO.PRESENT : PART_PHOTO.NOT_PRESENT;
                        photoFlag = photoFlag.indexOf(photoPresent) !== -1 ? photoFlag : [...photoFlag,photoPresent]
                    }
                    // console.log('here', product.PartPhotoFlag, photoFlag)
                })
                temp.keyword = keyWords
                temp.rohs = rohs;
                temp.techCourier = techCouriers
                temp.CSR = CSRFlag
           //     console.log('cons', photoFlag)
                temp.photoFlag = photoFlag
temp.FE=FE
            })
            temp.category = categories
            //  console.log('here',temp)
        })
        this.setState({ metadata: temp, itemsCount: count })
    }

    filterData = () => {
        const { filters, products } = this.state
        const { category, keyword, techCourier, rohs: rohsFilter, photoFlag, CSR ,FE} = filters;
        // console.log('check', category, filters, metadata, products)
        let temp = {}
        let categoryFilters = category || [];
        Object.entries(products || {}).filter(([key, value]) => categoryFilters.length === 0 || (categoryFilters.indexOf(key) !== -1)).forEach(([key, value], index) => {
            let keywordFilters = keyword || [];
            Object.entries(value).filter(([catKey, catValue]) => keywordFilters.length === 0 || (keywordFilters.indexOf(catKey) !== -1)).forEach(([catKey, catValue], index) => {
                catValue.filter(product => {
                   // console.log('check beck',product.PartPhotoFlag, product.PartPhotoFlag ? 'ON' : 'OFF', photoFlag)
                    return (techCourier.length === 0 || (techCourier.indexOf(product.TechCourier) !== -1))
                        && (rohsFilter.length === 0 || (rohsFilter.indexOf(product.RohsCompliant) !== -1))
                        && (CSR.length === 0 || (CSR.indexOf(product.CsrFlag) !== -1))
// && (FE.length === 0 || (FE.indexOf(product.FE_MATERIAL) !== -1))
                        && (!FE || FE === PART_FE.NOT_APPLICABLE 
                            || FE === (product.FE_MATERIAL ? PART_FE.PRESENT : PART_FE.NOT_PRESENT))                        && (!photoFlag || photoFlag === PART_PHOTO.NOT_APPLICABLE 
                            || photoFlag === (product.PartPhotoFlag ? PART_PHOTO.PRESENT : PART_PHOTO.NOT_PRESENT))
                    ///&& (CSR === undefined || (product.CsrFlag ? true : false) === photoFlag)
                }).forEach(element => {
                    const category = element.CategoryName
                    const keyword = element.KeywordName

                    let cat = temp[category] || {}
                    let keyw = cat[keyword] || []
                    keyw.push(element)

                    temp[category] = temp[category] || {}
                    temp[category][keyword] = keyw
                })
            })
               console.log('here filter',temp)
        })
        // if(applyField)
        // {
        //     temp = {...temp, [applyField]: metadata[applyField]}
        // }
        this.setState({ filteredData: temp })

    }

    getPhotoFrame = (partNumber) => `<iframe src="http://partsurfer.hp.com/ShowPhoto.aspx?partnumber=${partNumber}" width="540" height="450"></iframe>`;


    getCategoryProducts = (callback) => {
        let temp = {}
        const { data } = this.props;
        data.forEach(element => {
            const category = element.CategoryName
            const keyword = element.KeywordName

            let cat = temp[category] || {}
            let keyw = cat[keyword] || []
            keyw.push(element)

            temp[category] = temp[category] || {}
            temp[category][keyword] = keyw

        })

        this.setState({ products: temp }, () => {
            this.generateMetdata()
            this.filterData()
            callback && callback()
        })
    }

    triggerAll = (shouldExpand, key) => {
        const { products } = this.state;
        // console.log('key', key, shouldExpand)
        let status = {};
        if (key) {
            const main = products[key] || {}
            Object.keys(main).forEach(catKey => {
                status[`${key}-${catKey}-collapsed`] = shouldExpand
            })
            shouldExpand && !this.state[`${key}-collapsed`]
                ? this.setState({ ...this.state, ...status, [`${key}-triggered`]: shouldExpand, [`${key}-collapsed`]: shouldExpand })
                : this.setState({ ...this.state, ...status, [`${key}-triggered`]: shouldExpand })
        }
        else {
            Object.keys(products).forEach(key => {
                status[`${key}-collapsed`] = shouldExpand
            })
            this.setState({ ...this.state, ...status, triggered: shouldExpand })
        }
    }

    changeFilters = (key, value, isMulti = false, isFlag = false) => {
        // console.log('changefilter', key, value, isMulti)
        const { filters } = this.state;
        if (isFlag) {
            this.setState({ filters: { ...filters, [key]: value } }, () => this.generateMetdata())
        }
        else if (isMulti) {
            this.setState({ filters: { ...filters, [key]: (value || []).map(selection => selection.value) } })//, () => this.generateMetdata())
        }
        else {
            this.setState({ filters: { ...filters, [key]: value ? isObject(value) ? value.value : value : '' } }, () => this.generateMetdata())
        }
    }

    handleModalShowHide = () => {
        this.setState({ showHide: !this.state.showHide });
      };


      GoToLast=()=>{
        //alert(posToScroll);
        var scrollHeight=document.body.scrollHeight;
        alert(scrollHeight);
        var posToScroll=scrollHeight-(scrollHeight*13)/100;
       // alert(posToScroll)
       //alert(scrollHeight);
       window.scroll({top:posToScroll,behavior:"smooth"})
      };

    render() {
        const { data, mobileView } = this.props;
        const { filters, itemsCount,isPrint, Ishide,isSbom,SbomText } = this.state;
        const searchText = getQueryParam(this.props, SEARCH_TEXT);
        console.log("inside prodresult",data)
        // console.log('filters',filters);
/*         if(isPrint){
            return <DescProductListPrint  data={catValue}/>
        } */

        return (
            <React.Fragment>

                {/* <Details mode='PRODUCT' payload={data} expandIconRequired={true} triggered={this.state.triggered} triggerAll={this.triggerAll} /> */}
                { isPrint ?(
                        <DescProductListPrint data={this.props.data}/>
                        )
                    :mobileView
                    ?
                    <React.Fragment>
                        <div className="btn-print-friendly" >
                            {secureLocalStorage.getItem("loggeduser") != null ?                            
                            <Link rel="noopener noreferrer" className="apply btn btn-primary" to={`/PartSurfer/Sbom/${data[0].ProductNumber}`}>
                                {SbomText}
                            </Link>
                              : ''}              
                            &nbsp;&nbsp;
                            <Button className="apply btn btn-primary" onClick={() => this.PrintFriendlyView()} >Print View</Button> 
                        </div>   
                        <br/>
                        <Accordion defaultActiveKey="1" >
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    Click here to customize your search results
                                    <div >
                                        {itemsCount ? <div>{itemsCount} Records found</div> : 'no'}
                                    </div>

                                    
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0" >
                                    <Card.Body >
                                        <Form >
                                            <Row>
                                                <Col>
                                                    <Form.Group as={Row} controlId="formHorizontalCat">
                                                        <Form.Label column md={'5'} lg={'4'} xl={'3'} >
                                                            Category:
                                                </Form.Label>
                                                        <Col md={'12'} lg={'8'} xl={'8'}>
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                
                                                                // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                closeMenuOnSelect={false}
                                                                onMenuClose={this.generateMetdata}
                                                                isSearchable
                                                                isMulti
                                                                value={(filters?.category || []).map(ele => ({ value: ele, label: ele }))}
                                                                onChange={(option) => this.changeFilters('category', option, true)}
                                                                options={(this.state.metadata?.category || []).map(key => ({ value: key, label: key }))} /></Col>
                                                    </Form.Group>
                                                </Col>
                                            {/* </Row>
                                            <Row> */}
                                                <Col>
                                                    <Form.Group as={Row} controlId="formHorizontalRohs">
                                                        <Form.Label column sm={'3'} lg={'4'} xl={'4'} md={'4'}>
                                                            ROHS:
                                                    </Form.Label>
                                                        <Col sm={'12'} lg={'8'} md={'8'} xs={'12'} xl={'8'} >
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                closeMenuOnSelect={false}
                                                                onMenuClose={this.generateMetdata}
                                                                isSearchable
                                                                onChange={(option) => this.changeFilters('rohs', option, true)}
                                                                isMulti={true}
                                                                value={(filters?.rohs || []).map(ele => ({ value: ele, label: ele }))}
                                                                options={(this.state.metadata?.rohs || []).map(key => ({ value: key, label: key }))}
                                                            />

                                                        </Col>
                                                    </Form.Group>
                                                </Col>
                                            {/* </Row>
                                            <Row> */}
                                                <Col>
                                                    <Form.Group as={Row} controlId="formHorizontalKey">
                                                        <Form.Label column sm={'3'} lg={'4'} xl={'3'}>
                                                            Keyword:
                                                    </Form.Label>
                                                        <Col sm={'12'} lg={'8'} md={'6'} xs={'12'} xl={'8'}>
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                closeMenuOnSelect={false}
                                                                onMenuClose={this.generateMetdata}
                                                                isSearchable
                                                                value={(filters?.keyword || []).map(ele => ({ value: ele, label: ele }))}
                                                                isMulti
                                                                onChange={(option) => this.changeFilters('keyword', option, true)}
                                                                options={(this.state.metadata?.keyword || []).map(key => ({ value: key, label: key }))} />
                                                        </Col>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                {secureLocalStorage.getItem("loggeduser") != null ?
                                                    <Form.Group as={Row} controlId="formHorizontalTech">
                                                        <Form.Label column sm={'3'} lg={'4'} xl={'4'} md={'4'} >
                                                            Tech Courier:
                                                        </Form.Label>
                                                        <Col sm={'12'} lg={'8'} md={'8'} xs={'12'} xl={'8'}>
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                closeMenuOnSelect={false}
                                                                onMenuClose={this.generateMetdata}
                                                                isSearchable
                                                                // Value={['-N/A-']}
                                                                value={(filters?.techCourier || []).map(ele => ({ value: ele, label: ele }))}
                                                                isMulti={true}
                                                                onChange={(option) => this.changeFilters('techCourier', option, true)}
                                                                options={(this.state.metadata?.techCourier || []).map(key => ({ value: key, label: key })) || []} />
                                                        </Col></Form.Group>
                                                : "" }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group as={Row} controlId="formHorizontalCSR">
                                                        <Form.Label column md={'5'} lg={'3'} xl={'2'} >
                                                            CSR:
                                                    </Form.Label>
                                                        <Col md={'12'} lg={'8'} xl={'8'}>
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                closeMenuOnSelect={false}
                                                                onMenuClose={this.generateMetdata}
                                                                isSearchable
                                                                // Value={['-N/A-']}
                                                                value={(filters?.CSR || []).map(ele => ({ value: ele, label: ele }))}
                                                                isMulti={true}
                                                                onChange={(option) => this.changeFilters('CSR', option, true)}
                                                                options={(this.state.metadata?.CSR || []).map(key => ({ value: key, label: key })) || []} />
                                                        </Col>
                                                    </Form.Group>
                                                </Col>

                                            </Row>
                                       <Row>
                                                <Col>
                                                    <Form.Group as={Row} controlId="formHorizontalFE">
                                                        <Form.Label column md={'5'} lg={'3'} xl={'2'} >
                                                            FE:
                                                    </Form.Label>
                                                        <Col md={'12'} lg={'8'} xl={'8'}>
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                value={ filters?.FE && { value: filters?.FE , label: filters?.FE }}
                                                                onChange={(option) => this.changeFilters('FE', option)}
                                                                options={(this.state.metadata?.FE || []).map(key => ({ value: key, label: key })) || []} />
                                                        </Col>
                                                    </Form.Group>
                                                </Col>

                                            </Row> 
  <Row>
                                                <Col>
                                                <Form.Group as={Row} controlId="formHorizontalPhoto">
                                                    <Form.Label column md={'5'} lg={'3'} xl={'2'} >
                                                           Photo:
                                                </Form.Label>
                                                        <Col md={'12'} lg={'8'} xl={'8'}>
                                                             <Select
                                                                menuPortalTarget={document.body}
                                                                //closeMenuOnSelect={false}
                                                                //onMenuClose={this.generateMetdata}
                                                                //isSearchable
                                                                // Value={['-N/A-']}
                                                                value={ filters?.photoFlag && { value: filters?.photoFlag , label: filters?.photoFlag }}
                                                                onChange={(option) => this.changeFilters('photoFlag', option)}
                                                                options={(this.state.metadata?.photoFlag || []).map(key => ({ value: key, label: key })) || []} />
                                                       
                                                        </Col>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={'10'}>
                                                    <div className="searchbox" style={{ float: 'right' }}>
                                                        <button type="button" className="apply btn btn-primary" onClick={() => {
                                                            this.filterData()
                                                        }} >
                                                            Apply
                                                        </button>
                                                        &nbsp;&nbsp;
                                                        <button type="button" className="reset btn btn-primary" onClick={() => {
                                                            this.setState({ filters: initialFilters() }, () => { this.filterData() });
                                                        }} >Reset</button>
                                                        <br />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <br />
                        {
                            Object.entries(this.state.filteredData || {}).map(([key, value], index) => (
                                <div key={key}>
                                    <Accordion activeKey={this.state[`${key}-collapsed`] ? key : undefined}>
                                        <Card >
                                            <Row noGutters={true}>
                                                <Col xs={'11'} sm={'11'} md={'11'} lg={'11'} xl={'11'}>
                                                    <Accordion.Toggle
                                                        as={Card.Header}
                                                        eventKey={key}
                                                        onClick={() => {
                                                            this.setState({ [`${key}-collapsed`]: !this.state[`${key}-collapsed`] });
                                                        }}>
                                                        <span>{!this.state[`${key}-collapsed`] ?
                                                            <FontAwesomeIcon icon="chevron-up" /> :
                                                            <FontAwesomeIcon icon="chevron-down" />}</span>&nbsp;&nbsp;{key}

                                                    </Accordion.Toggle>
                                                </Col>

                                                <Col className="card-header" xs={'1'} sm={'1'} md={'1'} lg={'1'} xl={'1'}>
                                                    <div style={{ float: 'right', marginRight: '10px' }} onClick={() => this.triggerAll(this.state[`${key}-triggered`] ? false : true, key)}>
                                                        {!this.state[`${key}-triggered`] ? <FontAwesomeIcon icon="minus-square" /> : <FontAwesomeIcon icon="plus-square" />}
                                                        {/* <span onClick={() => this.triggerAll(false, key)}><i class="fas fa-minus-square"></i></span> */}
                                                    </div>

                                                </Col>
                                            </Row>

                                            <Accordion.Collapse eventKey={key}>
                                                <Card.Body key={key} style={{ overflow: "auto" }}>
                                                    <div>
                                                        {Object.entries(value || {}).map(([catKey, catValue], index) => (
                                                            <React.Fragment key={catKey}>
                                                                <Alert key={`${key}-${catKey}`}

                                                                    variant="dark"

                                                                    onClick={() => {
                                                                        this.setState({ [`${key}-${catKey}-collapsed`]: !this.state[`${key}-${catKey}-collapsed`] });
                                                                    }}
                                                                >
                                                                    {!this.state[`${key}-${catKey}-collapsed`] ? <FontAwesomeIcon icon="minus" /> : <FontAwesomeIcon icon="plus" />}&nbsp;&nbsp;{catKey}
                                                                </Alert>
                                                                <Collapse in={!this.state[`${key}-${catKey}-collapsed`]}>
                                                                    <div>
                                                                        <DescProductList data={catValue} />
                                                                    </div>
                                                                </Collapse>
                                                            </React.Fragment>))}
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>

                                        </Card>
                                    </Accordion>

                                </div>))
                        }
                    </React.Fragment>                   
                                    
                    :
                    <React.Fragment>
                        <div className="btn-print-friendly" >
                            {secureLocalStorage.getItem("loggeduser") != null ?                            
                            <Link rel="noopener noreferrer" className="apply btn btn-primary" to={`/PartSurfer/Sbom/${data[0].ProductNumber}`}>
                                {SbomText}
                            </Link>
                              : ''}              
                            &nbsp;&nbsp;
                            <Button className="apply btn btn-primary" onClick={() => this.PrintFriendlyView()} >Print View</Button> 
                        </div>   
                        <br/>
                        <Accordion defaultActiveKey="1" >
                            <Card >
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    Click here to customize your search results
                               <div style={{ float: 'right' }}>
                                        {itemsCount ? <div>{itemsCount} Records found</div> : 'no'}
                                        
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0" >
                                    <Card.Body >
                                        <Form >
                                            <Row>
                                                <Col>
                                                    <Form.Group as={Row} controlId="formHorizontalCat">
                                                        <Form.Label column md={'5'} lg={'4'} xl={'3'} >
                                                            Category:
                                                    </Form.Label>
                                                        <Col md={'12'} lg={'8'} xl={'8'}>
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                closeMenuOnSelect={false}
                                                                onMenuClose={this.generateMetdata}
                                                                isSearchable
                                                                isMulti
                                                                value={(filters?.category || []).map(ele => ({ value: ele, label: ele }))}
                                                                onChange={(option) => this.changeFilters('category', option, true)}
                                                                options={(this.state.metadata?.category || []).map(key => ({ value: key, label: key }))} /></Col>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group as={Row} controlId="formHorizontalRohs">
                                                        <Form.Label column md={'7'} lg={'4'} xl={'4'}>
                                                            ROHS:
                                                        </Form.Label>
                                                        <Col md={'12'} lg={'8'} xl={'8'} >
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                closeMenuOnSelect={false}
                                                                onMenuClose={this.generateMetdata}
                                                                isSearchable
                                                                onChange={(option) => this.changeFilters('rohs', option, true)}
                                                                isMulti={true}
                                                                value={(filters?.rohs || []).map(ele => ({ value: ele, label: ele }))}
                                                                options={(this.state.metadata?.rohs || []).map(key => ({ value: key, label: key }))}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group as={Row} controlId="formHorizontalCSR">
                                                        <Form.Label column md={'5'} lg={'3'} xl={'3'} >
                                                            CSR:
                                                    </Form.Label>
                                                        {filters?.CSR && <Col md={'12'} lg={'8'} xl={'8'}>
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                closeMenuOnSelect={false}
                                                                onMenuClose={this.generateMetdata}
                                                                isSearchable
                                                                // Value={['-N/A-']}
                                                                value={(filters?.CSR || []).map(ele => ({ value: ele, label: ele }))}
                                                                isMulti={true}
                                                                onChange={(option) => this.changeFilters('CSR', option, true)}
                                                                options={(this.state.metadata?.CSR || []).map(key => ({ value: key, label: key })) || []} />
                                                        </Col>}
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <Form.Group as={Row} controlId="formHorizontalKey">
                                                        <Form.Label column md={'5'} lg={'4'} xl={'3'}>
                                                            Keyword:
                                                </Form.Label>
                                                        <Col md={'12'} lg={'8'} xl={'8'}>
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                closeMenuOnSelect={false}
                                                                onMenuClose={this.generateMetdata}
                                                                isSearchable
                                                                value={(filters?.keyword || []).map(ele => ({ value: ele, label: ele }))}
                                                                isMulti
                                                                onChange={(option) => this.changeFilters('keyword', option, true)}
                                                                options={(this.state.metadata?.keyword || []).map(key => ({ value: key, label: key }))} />
                                                        </Col>
                                                    </Form.Group>
                                                </Col>                                                
                                                <Col>
                                                {secureLocalStorage.getItem("loggeduser") != null ?
                                                    <Form.Group as={Row} controlId="formHorizontalTech">
                                                        <Form.Label column md={'7'} lg={'5'} xl={'4'} >
                                                            Tech Courier:
                                                        </Form.Label>
                                                        <Col md={'12'} lg={'7'} xl={'8'}>
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                closeMenuOnSelect={false}
                                                                onMenuClose={this.generateMetdata}
                                                                isSearchable
                                                                // Value={['-N/A-']}
                                                                value={(filters?.techCourier || []).map(ele => ({ value: ele, label: ele }))}
                                                                isMulti={true}
                                                                onChange={(option) => this.changeFilters('techCourier', option, true)}
                                                                options={(this.state.metadata?.techCourier || []).map(key => ({ value: key, label: key })) || []} />
                                                        </Col></Form.Group>
                                                : ""}
                                                </Col> 
                                                <Col>
                                                    <Form.Group as={Row} controlId="formHorizontalPhoto">
                                                    <Form.Label column md={'5'} lg={'3'} xl={'3'} >
                                                           Photo:
                                                </Form.Label>
                                                        <Col md={'12'} lg={'8'} xl={'8'}>
                                                             <Select
                                                                menuPortalTarget={document.body}
                                                                //closeMenuOnSelect={false}
                                                                //onMenuClose={this.generateMetdata}
                                                                //isSearchable
                                                                // Value={['-N/A-']}
                                                                isClearable={true}
                                                                value={ filters?.photoFlag && { value: filters?.photoFlag , label: filters?.photoFlag }}
                                                                onChange={(option) => this.changeFilters('photoFlag', option)}
                                                                options={(this.state.metadata?.photoFlag || []).map(key => ({ value: key, label: key })) || []} />
                                                       
                                                        </Col>
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                        {/* New Row added for Functional Equivalent filter Start Here*/}
                                            <Row>
                                               <Col >
                                                    <Form.Group as={Row} controlId="formHorizontalFe">
                                                    <Form.Label column md={'5'} lg={'3'} xl={'3'} >
                                                           FE:
                                                </Form.Label>
                                                        <Col md={'12'} lg={'8'} xl={'8'}>
                                                             <Select
                                                                menuPortalTarget={document.body}
                                                                value={ filters?.FE && { value: filters?.FE , label: filters?.FE }}
                                                                onChange={(option) => this.changeFilters('FE', option)}
                                                                options={(this.state.metadata?.FE || []).map(key => ({ value: key, label: key })) || []} />
                                                       
                                                        </Col>
                                                    </Form.Group>
                                                </Col>                                                
                                                
                                                <Col style={{visibility: "hidden"}}>
                                                
                                                    <Form.Group as={Row} controlId="drgdf">
                                                        <Form.Label column md={'7'} lg={'5'} xl={'4'} >
                                                            Tech Courier:
                                                        </Form.Label>
                                                        <Col md={'12'} lg={'7'} xl={'8'}>
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                closeMenuOnSelect={false}
                                                                onMenuClose={this.generateMetdata}
                                                                isSearchable
                                                                // Value={['-N/A-']}
                                                                value={(filters?.techCourier || []).map(ele => ({ value: ele, label: ele }))}
                                                                isMulti={true}
                                                                onChange={(option) => this.changeFilters('techCourier', option, true)}
                                                                options={(this.state.metadata?.techCourier || []).map(key => ({ value: key, label: key })) || []} />
                                                        </Col></Form.Group>
                                                </Col> 
                                                <Col style={{visibility: "hidden"}}>
                                                    <Form.Group as={Row} controlId="abv">
                                                    <Form.Label column md={'5'} lg={'3'} xl={'3'} >
                                                           Photo:
                                                </Form.Label>
                                                        <Col md={'12'} lg={'8'} xl={'8'}>
                                                             <Select
                                                                menuPortalTarget={document.body}
                                                                //closeMenuOnSelect={false}
                                                                //onMenuClose={this.generateMetdata}
                                                                //isSearchable
                                                                // Value={['-N/A-']}
                                                                isClearable={true}
                                                                value={ filters?.photoFlag && { value: filters?.photoFlag , label: filters?.photoFlag }}
                                                                onChange={(option) => this.changeFilters('photoFlag', option)}
                                                                options={(this.state.metadata?.photoFlag || []).map(key => ({ value: key, label: key })) || []} />
                                                       
                                                        </Col>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
{/* New Row added for Functional Equivalent filter End Here*/}
                                            <Row>
                                                <Col>
                                                    <div className="searchbox" style={{ float: 'right' }}>
                                                        <button type="button" className="apply btn btn-primary" onClick={() => {
                                                            this.filterData()
                                                        }} >
                                                            Apply
                                            </button>
                                            &nbsp;&nbsp;
                                            <button type="button" className="reset btn btn-primary" onClick={() => {
                                                            this.setState({ filters: initialFilters() }, () => {
                                                                this.generateMetdata();
                                                                this.filterData()
                                                            });
                                                        }} >Reset</button>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </Form>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        <br />
                        {
                            Object.entries(this.state.filteredData || {}).map(([key, value]) => {
                                // console.log('aaa', `${key}-collapsed`, this.state[`${key}-collapsed`],value)
                                return (
                                    <div key={key}>
                                        <Accordion activeKey={this.state[`${key}-collapsed`] ? key : undefined } >
                                            {/* /this.state[`${key}-collapsed`] ? index : undefined}> */}
                                            <Card >
                                                <Row noGutters={true}>
                                                    <Col xs={'11'} sm={'11'} md={'11'} lg={'11'} xl={'11'}>
                                                        <Accordion.Toggle
                                                            as={Card.Header}
                                                            eventKey={key}
                                                            onClick={() => {
                                                                this.setState({ [`${key}-collapsed`]: !this.state[`${key}-collapsed`] });
                                                            }}>
                                                            <span>{!this.state[`${key}-collapsed`] ?
                                                                <FontAwesomeIcon icon="chevron-up" /> :
                                                                <FontAwesomeIcon icon="chevron-down" />}</span>&nbsp;&nbsp;{key}

                                                        </Accordion.Toggle>
                                                    </Col>

                                                    <Col className="card-header" xs={'1'} sm={'1'} md={'1'} lg={'1'} xl={'1'}>
                                                        <div style={{ float: 'right', marginRight: '10px' }} onClick={() => this.triggerAll(this.state[`${key}-triggered`] ? false : true, key)}>
                                                            {!this.state[`${key}-triggered`] ? <FontAwesomeIcon icon="minus-square" /> : <FontAwesomeIcon icon="plus-square" />}
                                                            {/* <span onClick={() => this.triggerAll(false, key)}><i class="fas fa-minus-square"></i></span> */}
                                                        </div>

                                                    </Col>
                                                </Row>
                                                <Accordion.Collapse eventKey={key} >
                                                    <Card.Body key={key} style={{ overflow: "auto" }}>
                                                        <div >
                                                            {Object.entries(value || {}).map(([catKey, catValue], index) => (
                                                                <React.Fragment key={catKey} >
                                                                    <Alert key={`${key}-${catKey}`}

                                                                        variant="dark"

                                                                        onClick={() => {
                                                                            this.setState({ [`${key}-${catKey}-collapsed`]: !this.state[`${key}-${catKey}-collapsed`] });
                                                                        }}
                                                                    >
                                                                        {!this.state[`${key}-${catKey}-collapsed`] ? <FontAwesomeIcon icon="minus" /> : <FontAwesomeIcon icon="plus" />}&nbsp;&nbsp;{catKey}
                                                                    </Alert>
                                                                    <Collapse in={!this.state[`${key}-${catKey}-collapsed`]}>
                                                                        <div>
                                                                            <DescProductList data={catValue} />
                                                                        </div>
                                                                    </Collapse>
                                                                </React.Fragment>))}
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>

                                            </Card>
                                        </Accordion>
                                    </div>
                                )
                            })
                        }
                
                    </React.Fragment>
    
                }
            </React.Fragment>
        )
    }
}

export default ViewHOC(ProductResults)