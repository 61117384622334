export const BASE_URL = `${process.env.REACT_APP_API}`
export const GET_COUNTRIES = (usertype) => `${BASE_URL}/Country/GetCountry/${usertype}`
export const GENERIC_SEARCH = (searchtext, country, usertype) => `${BASE_URL}/Search/GenericSearch/${searchtext}/country/${country || 'US'}/usertype/${usertype}`
export const GET_PARTLIST = (partNumber, usertype) => `${BASE_URL}/Part/GetWhereUsedProductList/${partNumber}/usertype/${usertype}`
export const PARTLIST = (partNumber,country, usertype) => `${BASE_URL}/Part/GetPart/${partNumber}/country/${country || 'US'}/usertype/${usertype}`
export const PRODUCTLIST = (productNumber, country, usertype) => `${BASE_URL}/Product/GetProduct/${productNumber}/country/${country || 'US'}/usertype/${usertype}`
export const SERIALNUMBER = (serialNumber, country, usertype) => `${BASE_URL}/SerialNumber/GetSerialNumber/${serialNumber}/country/${country || 'US'}/usertype/${usertype}`
export const FUNCTIONAL =(partNumber, countryCode, usertype) => `${BASE_URL}/Part/GetFunctionalEquivalent/${partNumber}/countryCode/${countryCode || 'US'}/usertype/${usertype}`
export const FUNCTIONAL_LIST =(productNumber, usertype) => `${BASE_URL}/FunctionalEqivalent/GetFunctionalEqivalent/${productNumber}/usertype/${usertype}`
export const FUNCTIONAL_PART =(partNumber, usertype) => `${BASE_URL}/FunctionalEqivalent/GetFunctionalEqivalentPart/${partNumber}/usertype/${usertype}`
export const SERIALWITHPRODUCT = (serialNumber,ProductNumber, country, usertype) => `${BASE_URL}/SerialNumber/GetSerialNumber/${serialNumber}/ProductNumber/${ProductNumber}/country/${country || 'US'}/usertype/${usertype}`
export const GET_HIERARCHY = (usertype) => `${BASE_URL}/Hierarchy/GetHierarchyInfo/usertype/${usertype}`
export const GETHIERARCHYPRODUCT = (OID, usertype) => `${BASE_URL}/Hierarchy/GetHierarchyProducts/${OID}/usertype/${usertype}`
export const SERIALWITHPRODUCTGENERAL = (serialNumber,ProductNumber, country, usertype) => `${BASE_URL}/SerialNumber/GetSerialNumberGeneral/${serialNumber}/ProductNumber/${ProductNumber}/country/${country || 'US'}/usertype/${usertype}`
export const INSERTFEEDBACK = () => `${BASE_URL}/Search/SendEmail`
export const INSERTPHOTOMETRIC = (PartNumber,Flag) => `${BASE_URL}/Search/InsertPhotoMetric/${PartNumber}/Flag/${Flag}`
export const CARTMETRIC = (PartNumber, Qty, Flag) => `${BASE_URL}/Search/CartMetric/${PartNumber}/Qty/${Qty}/Flag/${Flag}`
export const BUYMETRIC = (PartNumber) => `${BASE_URL}/Search/BuyMetric/${PartNumber}`
export const GETSBOM = (productNumber, countryCode, usertype) => `${BASE_URL}/Product/GetSBOM/${productNumber}/country/${countryCode || 'US'}/usertype/${usertype}`
export const GETUIDRESPONSE = (code) => `${BASE_URL}/Country/GetUIDResponse`
export const GENERATEREPORT = (username) => `${BASE_URL}/Search/GenerateMetricReport/${username}`
//Uncomment if format is not correct - Start
// // export const GETUIDTOKEN = (code) => `https://login-itg.external.hp.com/as/token.oauth2?redirect_uri=http://localhost:3000/PartSurfer/CallBack&grant_type=authorization_code&code=${code}`
// export const GETUIDTOKEN = (code) => `https://login.external.hp.com/as/token.oauth2?redirect_uri=${RESPONSEURLUID}&grant_type=authorization_code&code=${code}`
// //export const GETGLBURL = `https://partsurfer.hp.com/Search.aspx?code=hppinguid`
// //export const GETGLBURL = `https://itg-psurf-web.glbinc.hp.com/Search.aspx?code=hppinguid`

// // export const REDIRECTUIDURL = `https://login-itg.external.hp.com/as/authorization.oauth2?`
// export const REDIRECTUIDURL = `https://login.external.hp.com/as/authorization.oauth2?`
// // export const CLIENTID =`partsurfer-itg-uid-client`
// export const CLIENTID =`partsurferpro`
// //export const RESPONSEURLUID=`http://localhost:3000/PartSurfer/CallBack` //RedirectUrlUID
// // export const RESPONSEURLUID=`https://itg-psurf-web.glbinc.hp.com/PartSurfer/CallBack`
// //export const RESPONSEURLUID=`https://partsurfer.hp.com/PartSurfer/CallBack`
// export const RESPONSEURLUID=`https://pro-psurf-dr-web.inc.hp.com/PartSurfer/CallBack`
// export const GETGLBURL=  REDIRECTUIDURL + "client_id=" + CLIENTID + "&response_type=code&scope=email&redirect_uri=" +RESPONSEURLUID
// // export const TOKENURL=`https://login-itg.external.hp.com`
// export const TOKENURL=`https://login.external.hp.com`
// // export const tokencallurl = `login-itg.external.hp.com/as/token.oauth2?redirect_uri=${RESPONSEURLUID}`
// export const tokencallurl = `login.external.hp.com/as/token.oauth2?redirect_uri=${RESPONSEURLUID}`
// // export const UIDClientID=`partsurfer-itg-uid-client:RNotGHVuEujpS5wnnr5UKjqluWT3l1RK9UGibiHVRhhHDhhrJ8Bl8IUZP3TxNZpQ`
// export const UIDClientID=`partsurferpro:GFITxrswToMsAPvXrDV1TBVEMsLo5RhtibpidPHKvWuxnqeeKKsHC4980pNbH9qp`
//Uncomment if format is not correct - End
//Uncomment below lines for ITG - Execution - Start
//export const RESPONSEURLUID=`http://localhost:3000/PartSurfer/CallBack` //RedirectUrlUID
// export const RESPONSEURLUID=`https://itg-psurf-web.glbinc.hp.com/PartSurfer/CallBack`
// export const GETUIDTOKEN = (code) => `https://login-itg.external.hp.com/as/token.oauth2?redirect_uri=${RESPONSEURLUID}&grant_type=authorization_code&code=${code}`
// export const REDIRECTUIDURL = `https://login-itg.external.hp.com/as/authorization.oauth2?`
// export const CLIENTID =`partsurfer-itg-uid-client`
// export const TOKENURL=`https://login-itg.external.hp.com`
// export const tokencallurl = `login-itg.external.hp.com/as/token.oauth2?redirect_uri=${RESPONSEURLUID}`
// export const UIDClientID=`partsurfer-itg-uid-client:RNotGHVuEujpS5wnnr5UKjqluWT3l1RK9UGibiHVRhhHDhhrJ8Bl8IUZP3TxNZpQ`
// export const tokenurlforOAuth = `https://login-itg.external.hp.com/as/token.oauth2`
// export const clientvaluesforOAuth = `7c2Rz7BHZGdZNNGNJp6OKAvoIj7:vhTmC30uIbv8fPdXWdLUXKuwVohdhTtLGITkihIP75gyedWrfQVdBvYp5q4SfihY`
//Uncomment below lines for ITG - Execution - End
//Uncomment below lines for PROD - Execution - Start
export const RESPONSEURLUID=`https://partsurfer.hp.com/PartSurfer/CallBack`
export const GETUIDTOKEN = (code) => `https://login.external.hp.com/as/token.oauth2?redirect_uri=${RESPONSEURLUID}&grant_type=authorization_code&code=${code}`
export const REDIRECTUIDURL = `https://login.external.hp.com/as/authorization.oauth2?`
export const CLIENTID =`partsurferpro`
export const TOKENURL=`https://login.external.hp.com`
export const tokencallurl = `login.external.hp.com/as/token.oauth2?redirect_uri=${RESPONSEURLUID}`
export const UIDClientID=`partsurferpro:GFITxrswToMsAPvXrDV1TBVEMsLo5RhtibpidPHKvWuxnqeeKKsHC4980pNbH9qp`
export const tokenurlforOAuth = `https://login.external.hp.com/as/token.oauth2`
export const clientvaluesforOAuth = `DBXDR8NP6NILHCJRYQUB2L4FOME5SBM:fiXDWQCVyqfmTN1jI6GXjt8CB8raICrSYl8DjvZiQitUCCS0hSp1sa9PU4Jqwrze`
//Uncomment below lines for PROD - Execution - End
//export const GETGLBURL = `https://partsurfer.hp.com/Search.aspx?code=hppinguid`
//export const GETGLBURL = `https://itg-psurf-web.glbinc.hp.com/Search.aspx?code=hppinguid`
export const GETGLBURL=  REDIRECTUIDURL + "client_id=" + CLIENTID + "&response_type=code&scope=email&redirect_uri=" +RESPONSEURLUID