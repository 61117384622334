import React from 'react'

export default function PartImages({src,handleLoader}) {

  const loadComplete = () => {
    console.log("load complete");
    handleLoader(false);
  }

    return (
                    <img className="responsive" 
                    onLoad={loadComplete}                   
                    src={src}
                    alt="img"
                    
                    onError={(e) => {
                      e.target.src = {src} //replacement image imported above
                    }}
                   
                  /> 
                   
    )
}